import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Card from "../../../components/Card";
import CardEmpty from "../../../components/CardEmpty";
import CardSection from "../../../components/CardSection";
import Text from "../../../components/Text";
import Link from "../../../components/Link";
import Layout from "../../../components/Layout";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import InsuranceCertificate from "./InsuranceCertificate";
import Loading from "../../../components/Loading";
import Row from "../../../components/Row";
import { PrimaryButton, CtaButton } from "../../../components/Button";
import { Button } from "../../../components/Button/PrimaryButton";
import { routes, documents, countryCode } from "../../../config";
import * as moduleActions from "../actions";
import { downloadPdf } from "../../../helpers";

const LinkButton = styled(Button.withComponent("a"))`
  height: auto;
  min-height: 2.667em;
  display: flex;
  line-height: 1.35;
  white-space: normal;
  width: 100%;
  &:visited {
    color: white;
  }
`;

const ViewPolicy = ({ policy, user, fetchPolicyPdf, fetchPolicy, match }) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const id = match.params.id || policy.data.id;
  const { from, to, firstName, lastName, treatment, token } = policy.data;
  const isFertility = (policy.data.categoryId || "").toString() === "4";
  const isUK = countryCode === "UK";
  const isAU = countryCode === "AU";
  const isIE = countryCode === "IE";
  let certName = "Medical Travel Shield";
  if (isAU) {
    certName = "Medical Travel Shield Australia";
  }
  if (isIE) {
    certName = "Medical Travel Shield Ireland";
  }

  const initialFetch = async () => {
    await fetchPolicy({ id, params: { quote_token: token } });

    setInitialFetchComplete(true);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const travelDates = `${from} - ${to}`;
  const fullName = `${firstName} ${lastName}`;
  const headerText = (
    <span>
      Success! Thanks, {user.data.firstName}.<br />
      You have successfully purchased your insurance.
    </span>
  );

  const onDownloadClick = documentType => async () => {
    if (documentType === "ic") {
      const response = await fetchPolicyPdf({
        id,
        params: { quote_token: token },
      });
      const { filename, url } = response.data.data;

      downloadPdf(filename, url);
    }
  };

  if (!isInitialFetchComplete) {
    return <Loading />;
  }

  return (
    <Layout headerText={headerText}>
      <Row>
        <Columns>
          <Column flex="0 0 36%" display="flex">
            <Card headerText="Your Insurance Certificate">
              <CardSection>
                <InsuranceCertificate
                  name={certName}
                  travelDates={travelDates}
                  fullName={fullName}
                  treatment={treatment}
                />
                {isIE && (
                  <>
                    <br /><br />
                    <h3>Download Your Documents</h3>
                  </>
                )}
                <PrimaryButton
                  marginTop="1.111em"
                  onClick={onDownloadClick("ic")}
                >
                  {isIE ? "Insurance Schedule" : "Download Insurance Schedule (PDF)"}
                </PrimaryButton>
                {isAU && (
                  <LinkButton
                    target="_blank"
                    href={
                      isFertility
                        ? documents.fertilityWording.url
                        : documents.wording.url
                    }
                    download
                    marginTop="1.111em"
                  >
                    Download Product Disclosure & Policy Wording (PDF)
                  </LinkButton>
                )}
                {isUK && (
                  <LinkButton
                    target="_blank"
                    href={documents.tob.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.tob.text}
                  </LinkButton>
                )}
                {isUK && (
                  <LinkButton
                    target="_blank"
                    href={
                      isFertility
                        ? documents.ipidFertility.url
                        : documents.ipidCosmetic.url
                    }
                    download
                    marginTop="1.111em"
                  >
                    Download IPID
                  </LinkButton>
                )}
                {isUK && (
                  <LinkButton
                    target="_blank"
                    href={documents.demands.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.demands.text}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={isFertility ? documents.fertilityWording.url : documents.wording.url}
                    download
                    marginTop="1.111em"
                  >
                    {isFertility ? documents.fertilityWording.altText : documents.wording.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={isFertility ? documents.fertilitySummary.url : documents.summary.url}
                    download
                    marginTop="1.111em"
                  >
                    {isFertility ? documents.fertilitySummary.altText : documents.summary.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={isFertility ? documents.ipidFertility.url : documents.ipidCosmetic.url}
                    download
                    marginTop="1.111em"
                  >
                    {isFertility ? documents.ipidFertility.altText : documents.ipidCosmetic.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={documents.tob.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.tob.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={documents.demands.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.demands.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={documents.idd.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.idd.altText}
                  </LinkButton>
                )}
                {isIE && (
                  <LinkButton
                    target="_blank"
                    href={documents.declaration.url}
                    download
                    marginTop="1.111em"
                  >
                    {documents.declaration.altText}
                  </LinkButton>
                )}
              </CardSection>
            </Card>
          </Column>
          <Column flex="1 1 auto" display="flex">
            <Card headerText="Details">
              <CardSection>
                <Text marginBottom="1em">
                  An email has been sent to the address you provided, this will
                  also contain information about your cover and how to make a
                  claim under the policy.
                </Text>
                <Text marginBottom="1em">
                  If you have any issues or questions please don’t hesitate to
                  call us on:
                </Text>
                {isAU && (
                  <Text marginBottom="1em">
                    <Link href="tel:0061392581777">+61 (0) 3 9258 1777</Link>
                  </Text>
                )}
                {isUK && (
                  <Text marginBottom="1em">
                    <Link href="tel:00442034091236">+44(0)203 409 1236</Link>
                  </Text>
                )}
                {isIE && (
                  <Text marginBottom="1em">
                    <Link href="tel:00442034091236">+44(0)203 409 1236</Link>
                  </Text>
                )}
                <Text marginBottom="1em">
                  You can view your new policy or previous quotations by going
                  to the <Link to={routes.dashboard}>Dashboard</Link>.
                </Text>
              </CardSection>
            </Card>
          </Column>
        </Columns>
      </Row>
      <Row>
        <CardEmpty>
          <Text size="2em" color="#353535" weight="semibold" align="center">
            Need another Policy?
            <br />
            Get a quote
          </Text>
          <Link to={routes.quote} decoration="none">
            <CtaButton
              width="11.455em"
              marginTop="1.591em"
              marginLeft="auto"
              marginRight="auto"
            >
              Get a Quote
            </CtaButton>
          </Link>
        </CardEmpty>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => ({
  policy: state.policy,
  user: state.user,
});

const mapDispatchToProps = {
  fetchPolicy: moduleActions.fetchPolicy,
  fetchPolicyPdf: moduleActions.fetchPolicyPdf,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewPolicy);
