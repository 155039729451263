import React, { Fragment, useState } from "react";

import Link from "../../../components/Link";
import { countryCode, documents } from "../../../config";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Text from "../../../components/Text";
import InputCheckbox from "../../../components/InputCheckbox";
import List from "../../../components/List";
import { SecondaryButton } from "../../../components/Button";

const Sidebar = ({
  quoteData,
  buyQuotePathWithQuery,
  onDownloadQuoteClick,
}) => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const isCategoryChosen = quoteData.categoryId !== undefined;
  const isFertility = (quoteData.categoryId || "").toString() === "4";
  const isUK = countryCode === "UK";
  const isAU = countryCode === "AU";
  const isIE = countryCode === "IE";

  const handleChange = event => setHasAcceptedTerms(event.target.checked);

  return (
    <Card headerText="Purchase Policy">
      <CardSection padding="0.875em 1.625em">
        <Text color="#353535">Please review all of the documents</Text>
        <br />
        <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
          <Link
            as="div"
            style={{ cursor: "pointer" }}
            onClick={onDownloadQuoteClick}
          >
            Quotation Schedule
          </Link>
          {isAU && !isFertility && (
            <Link target="_blank" href={documents.wording.url}>
              {documents.wording.text}
            </Link>
          )}
          {isAU && isFertility && (
            <Link target="_blank" href={documents.fertilityWording.url}>
              {documents.fertilityWording.text}
            </Link>
          )}
          {isAU && (
            <Link target="_blank" href={documents.dutyOfDisclosure.url}>
              {documents.dutyOfDisclosure.text}
            </Link>
          )}
          {isAU && (
            <Link target="_blank" href={documents.financialServicesGuide.url}>
              {documents.financialServicesGuide.text}
            </Link>
          )}

          {isUK && !isFertility && (
            <Link target="_blank" href={documents.ipidCosmetic.url}>
              {documents.ipidCosmetic.altText}
            </Link>
          )}
          {isUK && isFertility && (
            <Link target="_blank" href={documents.ipidFertility.url}>
              {documents.ipidFertility.altText}
            </Link>
          )}
          {isUK && (
            <Link target="_blank" href={documents.tob.url}>
              {documents.tob.altText}
            </Link>
          )}
          {isUK && (
            <Link target="_blank" href={documents.demands.url}>
              {documents.demands.altText}
            </Link>
          )}


          {isIE && !isFertility && (
            <Link target="_blank" href={documents.wording.url}>
              {documents.wording.altText}
            </Link>
          )}
          {isIE && isFertility && (
            <Link target="_blank" href={documents.fertilityWording.url}>
              {documents.fertilityWording.altText}
            </Link>
          )}
          {isIE && !isFertility && (
            <Link target="_blank" href={documents.summary.url}>
              {documents.summary.altText}
            </Link>
          )}
          {isIE && isFertility && (
            <Link target="_blank" href={documents.fertilitySummary.url}>
              {documents.fertilitySummary.altText}
            </Link>
          )}
          {isIE && !isFertility && (
            <Link target="_blank" href={documents.ipidCosmetic.url}>
              {documents.ipidCosmetic.altText}
            </Link>
          )}
          {isIE && isFertility && (
            <Link target="_blank" href={documents.ipidFertility.url}>
              {documents.ipidFertility.altText}
            </Link>
          )}
          {isIE && (
            <Link target="_blank" href={documents.tob.url}>
              {documents.tob.altText}
            </Link>
          )}
          {isIE && (
            <Link target="_blank" href={documents.demands.url}>
              {documents.demands.altText}
            </Link>
          )}
          {isIE && (
            <Link target="_blank" href={documents.idd.url}>
              {documents.idd.altText}
            </Link>
          )}
          {isIE && (
            <Link target="_blank" href={documents.declaration.url}>
              {documents.declaration.altText}
            </Link>
          )}
        </List>

        {isUK && (
          <Text>
            We will handle your data in accordance with our{" "}
            <Link
              target="_blank"
              href={`${process.env.MTS_MAIN_SITE_URL}/terms-and-conditions`}
            >
              Privacy Policy
            </Link>
            .
          </Text>
        )}

        {isAU && (
          <Fragment>
            <Text weight="semibold" size="0.938rem">
              Terms, conditions, limits and exclusions apply. We do not provide
              any advice on this insurance based on any consideration of your
              objectives, financial situation or needs. Before making a decision
              please consider the Product Disclosure Statement.
            </Text>
            <br />
            <Text weight="semibold" size="0.938rem">
              Please make sure you have checked that you have provided the
              correct information.
            </Text>
          </Fragment>
        )}
        <br />
        <InputCheckbox
          name="terms"
          onChange={handleChange}
          renderLabel={Label => (
            <Fragment>
              {isUK && (
                <Label weight="semibold" size="0.938rem">
                  Yes, I have read and understood the Policy wording.
                </Label>
              )}
              {isIE && (
                <Label weight="semibold" size="0.938rem">
                  Yes, I have read and understood the Policy wording.
                </Label>
              )}
              {isAU && (
                <Label weight="semibold" size="0.938rem">
                  Yes, I have read and understood the Quotation Schedule,
                  Product Disclosure Statement, Duty of Disclosure and Financial
                  Services Guide
                </Label>
              )}
            </Fragment>
          )}
          value="1"
        />
        <br />
        <Link to={buyQuotePathWithQuery} decoration="none">
          <SecondaryButton disabled={!hasAcceptedTerms}>
            Purchase Policy
          </SecondaryButton>
        </Link>
      </CardSection>
    </Card>
  );
};

export default Sidebar;
